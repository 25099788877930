;(function($){
  $( document ).ready(function() {

    // Add class data-fancybox="image" to all links to images
    $('a[href]').not('[data-fancybox]').filter(function() {
      return /(\.jpg|\.jpeg|\.gif|\.png)/i.test($(this).attr('href'));
    }).attr('data-fancybox', 'image');

    // Add class .LightGallery to all links to Vimeo videos
    $('a[href]').not('[data-fancybox]').filter(function() {
        return /(?:www\.)?vimeo.com\/([0-9]+)/i.test( $(this).attr('href'));
    }).attr('data-fancybox', '');

    // Add class .LightGallery to all links to YouTube videos
    $('a[href]').not('[data-fancybox]').filter(function() {
        return /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/i.test( $(this).attr('href'));
    }).attr('data-fancybox', '');

    // Remove .LightGallery from selectors declared in admin
    $('.no-fancybox').removeAttr("data-fancybox");

    // Keep all the common settings in an object
    var gallerySettings = {
      loop: true,
      protect: true,
      arrows : true,
      infobar : true,
      toolbar : true,
      buttons : [
        'fullScreen',
        'close',
      ],
      helpers: {
        title: null
      },
      slideShow : {
        autoStart : false
      },
      iframe : {
        css : {
          width : '640px',
          height: '480px',
          margin: '0',
          preload : false
        }
      }
    };
    $('[data-fancybox]').fancybox(gallerySettings);
  });
})(jQuery);
