/* ========================================================================
 * BLULAB SCRIPTS
 * ======================================================================== */

(function ($) {

    // Masonry
    var masonryOption = {
        itemSelector: '.mansonry-item',
        transitionDuration: 0
    };

    var $grid = $('.wrapper-masonry').imagesLoaded(function () {
        $grid.masonry(masonryOption);
    });


    $(document).ready(function () {

        $('.counterUp').counterUp({
          delay: 10,
          time: 1000
        });

        // Activate justifiedGallery
        jQuery('.justifiedGallery.short').justifiedGallery({
            captions: false,
            rowHeight: 180,
            margins: 10,
            border: 0
        });

        //Scroll bottom
         $('.scrollbottom').click(function (e) {
            // prevent default action
            e.preventDefault();
            scrollToElement($(this).attr('href'), 1000);
        });

        //Masonry
        $('.wrapper-masonry').imagesLoaded(function () {
            $grid.masonry(masonryOption);
        });

        //Equal EL
        $('ul.menu li.capital-elem a span.menu-image-title').addClass('equal-el');

        // SLIDER
        $('.default-slider .slides').on('init', function (slick) {
            $('.default-slider .slides').removeClass('bl-hidden');
        }).slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            speed: 800,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnFocus: false,
            pauseOnHover: false
        });

        theEqualEL();

        //Immagine Full in Homepage
        $(".fulltop").css("height", $(window).height());
        $(window).resize(function () {
            $(".fulltop").css("height", $(window).height());
        });

    });

    theEqualEL = function () {
        $('.equal-el').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });
    };

    scrollToElement = function (el, ms) {
        var speed = (ms) ? ms : 600;
        $('html,body').animate({
            scrollTop: $(el).offset().top - 100
        }, speed);
    };

})(jQuery); // Fully reference jQuery after this point.
